<template>
  <div v-resize="onResize">
    <v-alert style="position:fixed;z-index:2;right:320px;"
      dismissible
      color="success"
      border="left"
      elevation="4"
      colored-border
      v-model="Alert"
      transition="slide-x-transition"
    >
      <v-icon class="mr-1">check-circle</v-icon>
      {{Pesan}}
    </v-alert>

    <div>
      <v-row no-gutters>
        <v-col cols="12" sm="6" md="5" lg="5">
          <span>Tanggal Pengajuan <span v-show="mobile == true">:</span></span>
        </v-col>
        <v-col cols="12" sm="6" md="7" lg="7" class="mt-n5">
          <v-dialog
            v-model="MenuTglPengajuan"
            :return-value.sync="date"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="Cuti.TanggalPengajuan"
                :append-icon="Cuti.TanggalPengajuan != 0 ? 'mdi-calendar-check' : 'mdi-calendar'"
                @click:append="MenuTglPengajuan = !MenuTglPengajuan"
                color="dark"
                readonly
                v-bind="attrs"
                v-on="on"
                :prefix="mobile ? '' : ':'"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="Cuti.TanggalPengajuan"
              @input="MenuTglPengajuan = false"
              year-icon="mdi-calendar-blank"
              color="red darken-4"
              class="rounded-lg"
              locale="id"
            ></v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" sm="6" md="5" lg="5">
          <span>Tanggal Mulai <span v-show="mobile == true">:</span></span>
        </v-col>
        <v-col cols="12" sm="6" md="7" lg="7" class="mt-n5">
          <v-dialog
            v-model="MenuTglMulai"
            :return-value.sync="date"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="Cuti.TanggalMulai"
                :append-icon="Cuti.TanggalMulai != 0 ? 'mdi-calendar-check' : 'mdi-calendar'"
                @click:append="MenuTglMulai = !MenuTglMulai"
                color="dark"
                readonly
                v-bind="attrs"
                v-on="on"
                :prefix="mobile ? '' : ':'"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="Cuti.TanggalMulai"
              @input="MenuTglMulai = false"
              year-icon="mdi-calendar-blank"
              color="red darken-4"
              class="rounded-lg"
              locale="id"
            ></v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" sm="6" md="5" lg="5">
          <span>Tanggal Selesai <span v-show="mobile == true">:</span></span>
        </v-col>
        <v-col cols="12" sm="6" md="7" lg="7" class="mt-n5">
          <v-dialog
            v-model="MenuTglSelesai"
            :return-value.sync="date"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="Cuti.TanggalSelesai"
                :append-icon="Cuti.TanggalSelesai != 0 ? 'mdi-calendar-check' : 'mdi-calendar'"
                @click:append="MenuTglSelesai = !MenuTglSelesai"
                color="dark"
                readonly
                v-bind="attrs"
                v-on="on"
                :prefix="mobile ? '' : ':'"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="Cuti.TanggalSelesai"
              :readonly="Cuti.TanggalMulai == '' ? true : false"
              @input="MenuTglSelesai = false"
              :min="Cuti.TanggalMulai == '' ? '2008-01-01' : Cuti.TanggalMulai"
              year-icon="mdi-calendar-blank"
              color="red darken-4"
              class="rounded-lg"
              locale="id"
            ></v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" sm="6" md="5" lg="5">
          <span>Kembali Bekerja Tanggal <span v-show="mobile == true">:</span></span>
        </v-col>
        <v-col cols="12" sm="6" md="7" lg="7" class="mt-n5">
          <v-dialog
            v-model="MenuTglKembaliKerja"
            :return-value.sync="date"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="Cuti.TanggalKerjaKembali"
                :append-icon="Cuti.TanggalKerjaKembali != 0 ? 'mdi-calendar-check' : 'mdi-calendar'"
                @click:append="MenuTglKembaliKerja = !MenuTglKembaliKerja"
                color="dark"
                readonly
                v-bind="attrs"
                v-on="on"
                :prefix="mobile ? '' : ':'"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="Cuti.TanggalKerjaKembali"
              :readonly="Cuti.TanggalSelesai == '' ? true : false"
              @input="MenuTglKembaliKerja = false"
              :min="Cuti.TanggalSelesai"
              year-icon="mdi-calendar-blank"
              color="red darken-4"
              class="rounded-lg"
              locale="id"
            ></v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" sm="6" md="5" lg="5">
          <span>Jam Mulai <span v-show="mobile == true">:</span></span>
        </v-col>
        <v-col cols="12" sm="6" md="7" lg="7" class="mt-n5">
          <v-text-field
            color="dark"
            v-model="Cuti.JamMulai"
            type="time"
            :prefix="mobile ? '' : ':'"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" sm="6" md="5" lg="5">
          <span>Jam Selesai <span v-show="mobile == true">:</span></span>
        </v-col>
        <v-col cols="12" sm="6" md="7" lg="7" class="mt-n5">
          <v-text-field
            v-model="Cuti.JamSelesai"
            color="dark"
            type="time"
            :prefix="mobile ? '' : ':'"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" sm="6" md="5" lg="5">
          <span>Jenis Cuti <span v-show="mobile == true">:</span></span>
        </v-col>
        <v-col cols="12" sm="6" md="7" lg="7" class="mt-n5">
          <v-autocomplete
            v-model="Cuti.JenisCuti"
            :items="['Cuti Tahunan','Cuti Besar','Cuti Sakit','Cuti Melahirkan','Cuti Penting']"
            color="dark"
            :prefix="mobile ? '' : ':'"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" sm="6" md="5" lg="5">
          <span>Alasan Cuti <span v-show="mobile == true">:</span></span>
        </v-col>
        <v-col cols="12" sm="6" md="7" lg="7" class="mt-n5">
          <v-textarea
            no-resize
            rows="2"
            v-model="Cuti.AlasanCuti"
            color="dark"
            hint="Berikan Alasan Cuti Anda!"
            persistent-hint
            :prefix="mobile ? '' : ':'"
          ></v-textarea>
        </v-col>
      </v-row>
    </div>

    <div v-show="mobile == false" class="mt-3">
      <v-btn height="45px" block depressed class="text-capitalize rounded-lg mb-3" color="red darken-4" dark @click="KirimCuti"><v-icon left>mdi-send</v-icon><span class="mr-1">Kirim Pengajuan</span></v-btn>
      <v-btn height="45px" block depressed :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'" class="text-capitalize rounded-lg" @click="SimpanCuti"><v-icon left>mdi-content-save</v-icon>Simpan Di Draf</v-btn>
    </div>
    
    <div v-show="mobile == true" class="mt-8">
      <v-btn height="55px" block depressed class="text-capitalize rounded-lg mb-3" color="red darken-4" dark @click="KirimCuti"><v-icon left>mdi-send</v-icon><span class="mr-1">Kirim Pengajuan</span></v-btn>
      <v-btn height="55px" block depressed :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'" class="text-capitalize rounded-lg" @click="SimpanCuti"><v-icon left>mdi-content-save</v-icon>Simpan Di Draf</v-btn>
    </div>
  </div>
</template>
<script>
import api from "@/services/http";
export default {
  data () {
    return {
        mobile:null,
        windowSize: {x: 0, y: 0},
        date: new Date().toISOString().substr(0, 10),
          notifications: false,
          token:localStorage.getItem('token'),
          MenuTglSelesai: false,
          MenuTglMulai: false,
          MenuTglKembaliKerja:false,
          IntervalAlert:'',
          MenuTglPengajuan: false,
          Cuti:{
            TanggalPengajuan:'',
            TanggalMulai:'',
            TanggalSelesai:'',
            JamMulai:'',
            JamSelesai:'',
            JenisCuti:'',
            AlasanCuti:'',
            TanggalKerjaKembali:'',
          },
          DefaultCuti:{
            TanggalPengajuan:this.date,
            TanggalMulai:'',
            TanggalSelesai:'',
            JamMulai:'',
            JamSelesai:'',
            JenisCuti:'',
            AlasanCuti:'',
            TanggalKerjaKembali:'',
          },
          Alert:false,
          Pesan:''
    }
  },

  mounted(){
    this.Cuti.TanggalPengajuan = this.date
  },

  computed: {
//
  },

  watch: {
    windowSize(){
      if (this.windowSize.x < 500) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },
  },

  methods:{ 
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },

    KirimCuti(){
      this.$emit('ToastKirim')
      // this.$parent.Toast('Form Cuti Dikirim')
      api.post('/karyawancuti?token='+this.token,{
        KodeKaryawan: this.$user.KodeKaryawan,
        TanggalPengajuan: this.Cuti.TanggalPengajuan,
        TanggalMulai: this.Cuti.TanggalMulai,
        TanggalSelesai: this.Cuti.TanggalSelesai,
        JamMulai: this.Cuti.JamMulai,
        JamSelesai: this.Cuti.JamSelesai,
        JenisCuti:this.Cuti.JenisCuti,
        AlasanCuti: this.Cuti.AlasanCuti,
        TujuanNotif:this.$user.jabatan.Atasan,
        TanggalKerjaKembali:this.Cuti.TanggalKerjaKembali,
        Level:1,
        StatusCuti:"Diajukan",
        Dari: this.$user.KodeKaryawan,
        },{ headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } })
        .then((res)=>{
          if (res) {
            console.log(res)
          // this.NotifCutiKaryawan()
            this.Cuti = Object.assign({}, this.DefaultCuti)
          } else {
            //
            this.Cuti = Object.assign({}, this.DefaultCuti)
          }
        })
    },
    SimpanCuti(){
      this.$emit('ToastSimpan')
      api.post('/karyawancuti?token='+this.token,{
        KodeKaryawan: this.$user.KodeKaryawan,
        TanggalPengajuan: this.Cuti.TanggalPengajuan,
        TanggalMulai: this.Cuti.TanggalMulai,
        TanggalSelesai: this.Cuti.TanggalSelesai,
        JamMulai: this.Cuti.JamMulai,
        JamSelesai: this.Cuti.JamSelesai,
        JenisCuti:this.Cuti.JenisCuti,
        AlasanCuti: this.Cuti.AlasanCuti,
        TanggalKerjaKembali:this.Cuti.TanggalKerjaKembali,
        TujuanNotif:null,
        StatusCuti:"Dibuat"
        },{ headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } })
        .then((res)=>{
          if (res) {
            // this.ShowAlert('Simpan')
            this.Cuti = Object.assign({}, this.DefaultCuti)
          } else {
            //
            this.Cuti = Object.assign({}, this.DefaultCuti)
          }
        })
    },
    NotifCutiKaryawan(){
      api.post('/notifikasi?token='+this.token,{
        Kategori: "Approval",
        Judul: "Pengajuan Cuti",
        Konten: this.Cuti.AlasanCuti,
        Attachment:null,
        Dari: this.$user.KodeKaryawan,
        Tujuan: "Human Resources",
        },
        { headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } }
        )
        .then((res)=>{
          if (res) {
            // this.Alert = true
            // console.log('ok lanjut')
          } else {
            //
          }
        })
    },
    ShowAlert(data){
      if (data == 'Simpan') {
        this.Pesan = 'Pengajuan Cuti Di Simpan Ke Draf'
        this.Alert = true
        clearInterval(this.IntervalAlert)
        this.AlertInterval()
      }else if(data == 'Kirim'){
        this.Pesan = 'Pengajuan Cuti Di Kirim Ke HR'
        this.Alert = true
        clearInterval(this.IntervalAlert)
        this.AlertInterval()
      }
    },
    AlertInterval(){
      this.IntervalAlert = setInterval(() => {
        this.Alert = false;
        this.Pesan = '';
      }, 3000)
    },
  }
}
</script>
