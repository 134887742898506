<template>
  <div>
    <!-- Loading list Data Cuti -->
    <v-list
      two-line
      outlined
      class="rounded-lg"
      v-show="loading == true"
    >
      <div class="pa-4">
        <v-skeleton-loader height="13px" width="200px" type="image" class="rounded-pill mb-1"></v-skeleton-loader>
        <v-skeleton-loader height="13px" width="150px" type="image" class="rounded-pill mb-n1"></v-skeleton-loader>
      </div>
      <v-divider></v-divider>
      <div class="pa-4">
        <v-skeleton-loader height="13px" width="200px" type="image" class="rounded-pill mb-1"></v-skeleton-loader>
        <v-skeleton-loader height="13px" width="150px" type="image" class="rounded-pill mb-n1"></v-skeleton-loader>
      </div>
    </v-list>

    <!-- Ketika list data cuti kosong -->
    <v-list
      two-line
      outlined
      class="rounded-lg"
      v-show="loading == false && items.length > 0 == false"
    >
      <v-row
        no-gutters
        class="fill-height ma-0"
        align="center"
        justify="center"
      >
        <span class="pa-3"><v-icon left>mdi-format-list-text</v-icon>Cuti Tidak Ada</span>
      </v-row>
    </v-list>
  
    <v-list 
      v-show="loading == false && items.length > 0 == true"
      two-line
      outlined
      class="rounded-lg"
    >
      <v-list-item-group
        v-model="selected"
        :color="$vuetify.theme.dark ? '' : 'grey darken-4'"
        mandatory
      >
        <template v-for="(item,index) in items">
          <v-list-item :key="index+'x'" @click="DialogPesan(item)">
            <v-list-item-content>
              <v-list-item-title v-text="item.JenisCuti"></v-list-item-title>
              <v-list-item-subtitle v-text="item.AlasanCuti"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text v-text="item.DibuatTgl"></v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < items.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>

    <!-- Dialog Detail Pesan -->
    <v-dialog
      v-model="DialogDetailPesan"
      max-width="400"
    >
      <v-card
        outlined
        class="rounded-lg"
      >
        <v-toolbar flat class="rounded-t-lg">
          <v-toolbar-title class="title">Detail Data Cuti</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="DialogDetailPesan = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div class="mr-n3 ml-n3 mb-n2 mt-3">
            <v-sheet outlined class="rounded-lg">
              <div v-show="loading == false">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Dibuat Oleh</v-list-item-title>
                    <v-list-item-subtitle v-text="''+DetailMsg.karyawan.Nama"/>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Diajukan Tanggal</v-list-item-title>
                    <v-list-item-subtitle v-text="''+DetailMsg.TanggalPengajuan"/>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Dari Tanggal</v-list-item-title>
                    <v-row dense no-gutters>
                      <div><v-list-item-subtitle v-text="''+DetailMsg.TanggalMulai"/></div>
                      <div class="mr-2 ml-2">S/D</div>
                      <div><v-list-item-subtitle v-text="''+DetailMsg.TanggalSelesai"/></div>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Jam Mulai</v-list-item-title>
                    <v-row dense no-gutters>
                      <div><v-list-item-subtitle v-text="''+DetailMsg.JamMulai"/></div>
                      <div class="mr-2 ml-2">S/D</div>
                      <div><v-list-item-subtitle v-text="''+DetailMsg.JamSelesai"/></div>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Jenis Cuti</v-list-item-title>
                    <v-list-item-subtitle v-text="''+DetailMsg.JenisCuti"/>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Alasan Cuti</v-list-item-title>
                    <v-list-item-subtitle v-text="''+DetailMsg.AlasanCuti"/>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-sheet>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/services/http";
  export default {
    props: ['Menu'],
    data: () => ({
      loading: true,
      DialogDetailPesan: false,
      selected: [0],
      DetailMsg:{
        AlasanCuti:'',
        DibuatOleh:'',
        DibuatTgl:'',
        DiubahOleh:'',
        DiubahTgl:'',
        JamMulai:'',
        JamSelesai:'',
        JenisCuti:'',
        KodeKaryawan:'',
        NoCuti:'',
        StatusCuti:'',
        TanggalMulai:'',
        TanggalPengajuan:'',
        TanggalSelesai:'',
        id:'',
          karyawan:{
            Nama:''
          }
      },
      token:localStorage.getItem('token'),
      items: [],
    }),
    watch:{
      Menu(){
        if(this.Menu == "DataCuti"){this.getData()}
      }
    },
    methods:{
      DialogPesan(item){
        this.DialogDetailPesan = true
        this.DetailMsg = item
        // console.log(this.DetailMsg)
      },
      getData(){
       api.post('/listcutikaryawan?token='+this.token,{
          KodeKaryawan: this.$user.KodeKaryawan
          },{ headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } })
          .then((res)=>{
            if (res) {
              this.loading = false
              // this.items = res.data.filter(data => data.StatusCuti == 'Dibuat')
              this.items = res.data
              // this.items.forEach(d => {
              //   d.DibuatTgl = d.DibuatTgl.substr(0,10)
              // });
              this.items = this.items.reverse()
              // console.log(this.items)
            }
          })
      }
    }
  }
</script>

<style scoped>
.v-app-bar {
  will-change: initial !important;
}
</style>