<template>
  <v-container fluid v-resize="onResize" class="scroll-y">
    <div style="max-width: 600px; margin: auto;">
      <v-card elevation="6" class="rounded-lg" :outlined="$vuetify.theme.dark ? true : false">
        <v-toolbar flat height="64px">
          <v-menu max-width="250px" rounded="lg">
            <template v-slot:activator="{ on, attrs }">
              <v-toolbar-title class="title">
                <span v-bind="attrs" v-on="on" v-show="Menu == 'PengajuanCuti'">Buat Pengajuan Cuti</span>
                <span v-bind="attrs" v-on="on" v-show="Menu == 'DataCuti'">Data Cuti</span>
                <span v-bind="attrs" v-on="on" v-show="Menu == 'DrafCuti'">Draf Cuti</span>
                <v-icon v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
              </v-toolbar-title>
            </template>

            <v-list width="220px" :outlined="$vuetify.theme.dark ? true : false" class="rounded-lg">
              <v-list-item-group
                v-model="selectedItem"
                mandatory
              >
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  @click="MenuForm(item.to)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-toolbar>

        <v-divider></v-divider>

        <div class="pa-2">
          <PengajuanCuti class="pa-3" v-show="Menu == 'PengajuanCuti'" :Menu="Menu" @ToastKirim="ToastKirim" @ToastSimpan="ToastSimpan"/>
          <DrafCuti v-show="Menu == 'DrafCuti'" :Menu="Menu"/>
          <DataCuti v-show="Menu == 'DataCuti'" :Menu="Menu"/>
        </div>
      </v-card>
    </div>

    <!-- <v-fab-transition v-show="mobile == false">
      <v-btn
        :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey darken-1'"
        v-scroll="onScroll"
        v-show="fab"
        fab
        small
        dark
        fixed
        bottom
        right
        @click="toTop"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition> -->
  </v-container>
</template>
<script>

// const PengajuanCuti = () => import (/* webpackChunkName: "chart-bar"*/ '@/views/FormCuti/PengajuanCuti')
// const DrafCuti = () => import (/* webpackChunkName: "chart-bar"*/ '@/views/FormCuti/DrafCuti')
// const DataCuti = () => import (/* webpackChunkName: "chart-bar"*/ '@/views/FormCuti/DataCuti')
import PengajuanCuti from '@/views/FormCuti/PengajuanCuti'
import DrafCuti from '@/views/FormCuti/DrafCuti'
import DataCuti from '@/views/FormCuti/DataCuti'
  export default {
    components: {
      PengajuanCuti,DrafCuti,DataCuti
    },
    props: ['FormCutiKaryawan'],
    data () {
      return {
        fab: false,
        MenuPengajuan: false,
        SizeMenuPengajuan: null,
        NavDrawerMenuPengajuan: true,
        mini: true,
        windowSize: {x: 0, y: 0},
        WindowSizeMenuPengajuan: {x: 0, y: 0},
        mobile:null,
        Menu:'PengajuanCuti',
        selectedItem: 0,
        items: [
          { text: 'Buat Pengajuan Cuti', icon: 'mdi-square-edit-outline', to: 'PengajuanCuti' },
          { text: 'Data Cuti', icon: 'mdi-file-document-edit-outline', to: 'DataCuti' },
          { text: 'Draf Cuti', icon: 'mdi-content-save-edit', to: 'DrafCuti' },
        ],
      }
    },
    watch:{
      windowSize(){
        if (this.windowSize.x < 600) {
          this.mobile = true
        }else{
          this.mobile = false
        }
      },

      WindowSizeMenuPengajuan(){
        if (this.WindowSizeMenuPengajuan.x < 1300) {
          this.titleClass = "d-none"
          this.SizeMenuPengajuan = true
        }else{
          this.titleClass = "mr-4"
          this.SizeMenuPengajuan = false
        }
      },
    },
    methods:{
      onScroll (e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset ||   e.target.scrollTop || 0
        this.fab = top > 20
      },
      toTop () {
        this.$vuetify.goTo(0)
      },

      wadow(){
        console.log('wkwaokwokw')
      },
      onResize(){
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
        this.WindowSizeMenuPengajuan = { x: window.innerWidth, y: window.innerHeight }
      },
        ToastKirim(){
          this.$swal.fire({
          toast: true,
          icon: 'success',
          title: 'Form Cuti Dikirim',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true})
          this.Menu = "DataCuti"
        },
        ToastSimpan(){
          this.$swal.fire({
          toast: true,
          icon: 'success',
          title: 'Form Cuti Disimpan di Draf',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true})
          this.Menu = "DrafCuti"
        },
        Tutup(){
        this.$emit('FormCutiKaryawan',false)
        this.Cuti = Object.assign({}, this.DefaultCuti)
        },
        MenuForm(data){
          this.Menu = data
        },
        SimpanCuti(){
          // alert('ok')
        },
        KirimCuti(){
          // alert('kirim')
        }
    }
  }
</script>