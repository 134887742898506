<template>
  <div>
    <!-- Loading list Draf Cuti -->
    <v-list
      two-line
      outlined
      class="rounded-lg"
      v-show="loading == true"
    >
      <div class="pa-4">
        <v-skeleton-loader height="13px" width="200px" type="image" class="rounded-pill mb-1"></v-skeleton-loader>
        <v-skeleton-loader height="13px" width="150px" type="image" class="rounded-pill mb-n1"></v-skeleton-loader>
      </div>
      <v-divider></v-divider>
      <div class="pa-4">
        <v-skeleton-loader height="13px" width="200px" type="image" class="rounded-pill mb-1"></v-skeleton-loader>
        <v-skeleton-loader height="13px" width="150px" type="image" class="rounded-pill mb-n1"></v-skeleton-loader>
      </div>
    </v-list>

    <!-- Ketika list data cuti kosong -->
    <v-list
      two-line
      outlined
      class="rounded-lg"
      v-show="loading == false && items.length > 0 == false"
    >
      <v-row
        no-gutters
        class="fill-height ma-0"
        align="center"
        justify="center"
      >
        <span class="pa-3"><v-icon left>mdi-format-list-text</v-icon>Draf Cuti Tidak Ada</span>
      </v-row>
    </v-list>

    <v-list 
      v-show="loading == false && items.length > 0 == true"
      two-line
      class="rounded-lg"
    >
      <v-list-item-group
        v-model="selected"
        :color="$vuetify.theme.dark ? '' : 'grey darken-4'"
        mandatory
      >
        <template v-for="(item,index) in items">
          <v-list-item :key="index+'x'" @click="DialogPesan(item)">
            <v-list-item-content >
              <v-list-item-title v-text="item.JenisCuti"></v-list-item-title>
              <v-list-item-subtitle v-text="item.AlasanCuti"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text v-text="item.DibuatTgl"></v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < items.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>

    <v-dialog
      v-model="DialogDetailPesan"
      max-width="600"
    >
      <v-card
        outlined
        class="rounded-lg"
      >
        <v-toolbar flat class="rounded-t-lg">
          <v-toolbar-title>Detail Draf</v-toolbar-title>
          <v-spacer></v-spacer>

          <!-- Menu Hapus Pesan -->
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-sheet outlined>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item @click="DialogDetailPesan = false">
                    <v-list-item-icon class="mr-3">
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Hapus Draf</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-sheet>
          </v-menu>
          <v-btn icon @click="DialogDetailPesan = false">
            <v-icon large>mdi-chevron-left</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <div class="mt-5">
            <!-- <v-sheet
              v-for="(DetailMsg,index) in DetailMsg"
              :key="index" class="d-flex justify-start pa-1"
            > -->
            <div v-show="loading == false">
              <v-sheet outlined class="pa-3">
                <div class="subtitle-2">
                  <v-btn class="mx-3" dark color="red darken-4" @click="EditDraf"><v-icon>mdi-pencil</v-icon></v-btn>
                  <v-btn dark color="red darken-4" @click="DeleteDraf"><v-icon>mdi-delete</v-icon></v-btn>
                </div>
              </v-sheet>
              <v-sheet outlined class="pa-3">
                <div class="subtitle-2">
                  <label v-text="'Dibuat : '+DetailMsg.karyawan.Nama"/>
                </div>
              </v-sheet>
              <v-sheet outlined class="pa-3">
                <div class="subtitle-2">
                  <label v-text="'Diajukan Tanggal : '+DetailMsg.TanggalPengajuan"/>
                </div>
              </v-sheet>
              <v-sheet outlined class="pa-3">
                <div class="subtitle-2">
                  <label v-text="'Dari Tanggal : '+DetailMsg.TanggalMulai"/>
                  <label v-text="' S/D '+DetailMsg.TanggalSelesai"/>
                </div>
              </v-sheet>
              <v-sheet outlined class="pa-3">
                <div class="subtitle-2">
                  <label v-text="'Jam Mulai : '+DetailMsg.JamMulai"/>
                  <label v-text="' S/D '+DetailMsg.JamSelesai"/>
                </div>
              </v-sheet>
              <v-sheet outlined class="pa-3">
                <div class="subtitle-2">
                  <label v-text="'Jenis Cuti : '+DetailMsg.JenisCuti"/>
                </div>
              </v-sheet>
              <v-sheet outlined class="pa-3">
                <div class="subtitle-2">
                  <label v-text="'Alasan Cuti : '+DetailMsg.AlasanCuti"/>
                </div>
              </v-sheet>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/services/http";
  export default {
    props: ['Menu'],
    data: () => ({
      loading: true,
      DialogDetailPesan: false,
      DetailMsg:{
        AlasanCuti:'',
        DibuatOleh:'',
        DibuatTgl:'',
        DiubahOleh:'',
        DiubahTgl:'',
        JamMulai:'',
        JamSelesai:'',
        JenisCuti:'',
        KodeKaryawan:'',
        NoCuti:'',
        StatusCuti:'',
        TanggalMulai:'',
        TanggalPengajuan:'',
        TanggalSelesai:'',
        id:'',
          karyawan:{
            Nama:''
          }
      },
      token:localStorage.getItem('token'),
      selected: [0],
      items: [],
    }),
    watch:{
      Menu(){
        if(this.Menu == "DrafCuti"){this.getData()}
      }
    },
    mounted(){
      //
    },
    methods:{
      DialogPesan(item){
        this.DialogDetailPesan = true
        this.DetailMsg = item
        // console.log(this.DetailMsg)
      },
      EditDraf(){
        alert('edit')
      },
      DeleteDraf(){
        alert('ok')
      },
      getData(){
       api.post('/listcutikaryawan?token='+this.token,{
          KodeKaryawan: this.$user.KodeKaryawan
          },{ headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } })
          .then((res)=>{
            if (res) {
              this.loading = false
              this.items = res.data.filter(data => data.StatusCuti == 'Dibuat')
              // this.items.forEach(d => {
              //   d.DibuatTgl = d.DibuatTgl.substr(0,10)
              // });
            this.items = this.items.reverse()
            }
          })
      }
    }
  }
</script>

<style scoped>
.v-app-bar {
  will-change: initial !important;
}
</style>